import { httpAction } from './HttpAction';
import {
  CREATE_PLANT,
  GET_PLANTS,
  GET_PLANT_OPTIONS_LIST,
  UPDATE_PLANT,
  GET_PLANT,
  DELETE_PLANT
} from './ActionNames';
import { PLANT_EP, DELETE, GET, POST, PUT } from 'src/constants/ApiConstants';

export const getPlants = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PLANTS,
        url: PLANT_EP + '/all',
        verb: GET
      })
    );
  };
};

export const getPlantOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PLANT_OPTIONS_LIST,
        url: PLANT_EP + '/option-list',
        verb: GET
      })
    );
  };
};

export const createPlant = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_PLANT,
        url: PLANT_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updatePlant = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_PLANT,
        url: PLANT_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const getPlant = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PLANT,
        url: PLANT_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const deletPlant = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: DELETE_PLANT,
        url: PLANT_EP + '/delete/' + id,
        verb: DELETE
      })
    );
  };
};
