export const EMPTY_STRING = '';
export const OBJECT = 'object';
export const STRING = 'string';
export const NUMBER = 'number';
export const ALERT_TYPE_ERROR = 'error';
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_WARNING = 'warning';
export const ALERT_TYPE_INFORMATIONAL = 'info';
export const ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const API_ACTION = 'API_ACTION';

// Date Constants
export const DATETIMEFORMAT = 'MM/DD/YYYY-ddd';
export const FORMSUPPORTEDDATEFORMAT = 'yyyy-MM-dd';
export const MONTH = 'month';
export const YEAR = 'year';
export const DAY = 'day';
