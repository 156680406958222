import { httpAction } from './HttpAction';

import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER_OPTIONS_LIST
} from './ActionNames';

import {
  CUSTOMER_EP,
  DELETE,
  GET,
  POST,
  PUT
} from 'src/constants/ApiConstants';

export const getCustomers = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_CUSTOMERS,
        url: CUSTOMER_EP + '/all',
        verb: GET
      })
    );
  };
};

export const getCustomer = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_CUSTOMER,
        url: CUSTOMER_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const getCustomerOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_CUSTOMER_OPTIONS_LIST,
        url: CUSTOMER_EP + '/option-list',
        verb: GET
      })
    );
  };
};

export const createCustomer = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_CUSTOMER,
        url: CUSTOMER_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updateCustomer = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_CUSTOMER,
        url: CUSTOMER_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const deleteCustomer = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: DELETE_CUSTOMER,
        url: CUSTOMER_EP + '/delete/' + id,
        verb: DELETE
      })
    );
  };
};
