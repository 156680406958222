import { EMPTY_STRING } from 'src/constants/AppConstants';

export const httpActionTemplate = {
  type: EMPTY_STRING,
  url: null,
  verb: EMPTY_STRING,
  params: null,
  payload: null
};

export const httpAction = (httpAction: any) => {
  return {
    HTTP_ACTION: Object.assign({}, httpActionTemplate, httpAction)
  };
};
