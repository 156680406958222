/* eslint-disable @typescript-eslint/default-param-last */
import { Purchase } from 'src/types/Purchase';

import {
  GET_PURCHASE_REQUESTED,
  GET_PURCHASE_RECEIVED,
  GET_PURCHASE_FAILURE
} from '../actions/ActionNames';

export interface PurchaseState {
  data: Purchase;
  isLoading: boolean;
}

const initialState: PurchaseState = {
  data: {
    purchaseDate: ''
  },
  isLoading: false
};

const PurchaseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PURCHASE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PURCHASE_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PURCHASE_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default PurchaseReducer;
