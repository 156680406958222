// error codes
export const FOUR_O_FOUR = 404;
export const FIVE_O_O = 500;
export const FOUR_O_THREE = 403;
export const FOUR_O_ONE = 401;
//API Endpoints
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const BANK_EP = '/bank';
export const COMPANY_EP = '/company';
export const CUSTOMER_EP = '/customer';
export const PRODUCT_EP = '/product';
export const PLANT_EP = '/plant';
export const TRANSPORTER_EP = '/transporter';
export const USER_EP = '/user';
export const VEHICLE_EP = '/vehicle';
export const PURCHASE_EP = '/purchase';
export const INVOICE_EP = '/invoice';
