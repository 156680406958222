/* eslint-disable @typescript-eslint/default-param-last */
import { Bank } from 'src/types/Bank';

import {
  GET_BANK_REQUESTED,
  GET_BANK_RECEIVED,
  GET_BANK_FAILURE
} from '../actions/ActionNames';

export interface BankState {
  data: Bank;
  isLoading: boolean;
}

const initialState: BankState = {
  data: {
    id: '',
    name: '',
    accountNumber: '',
    ifscCode: ''
  },
  isLoading: false
};

const BankReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_BANK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_BANK_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_BANK_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default BankReducer;
