import { OutlinedInput } from '@mui/material';

import { Box, Typography } from '@mui/material';
import { ErrorMessage, Field } from 'formik';

const FormField = ({ name, label, error, ...rest }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box mb={0.25} mt={0.25} ml={0.5} lineHeight={0} display="inline-block">
        <Typography
          component="label"
          variant="caption"
          fontWeight="bold"
          color={!error ? 'primary' : 'error'}
          textTransform={'capitalize'}
        >
          {label}
        </Typography>
      </Box>
      <Field
        name={name}
        error={error}
        {...rest}
        as={OutlinedInput}
        size="small"
      />
      <Box mt={0.01} ml={0.5} lineHeight={0} display="inline-block">
        <Typography
          component="p"
          variant="caption"
          color="error"
          textTransform={'capitalize'}
        >
          <ErrorMessage name={name} />
        </Typography>
      </Box>
    </Box>
  );
};

export default FormField;
