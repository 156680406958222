/* eslint-disable @typescript-eslint/default-param-last */
import { Reducer } from 'redux';
import {
  ADD_GLOBAL_MESSAGE,
  REMOVE_GLOBAL_MESSAGE
} from '../actions/ActionNames';
import { GlobalMessage } from 'src/types/GlobalMessage';

export interface GlobalMessagesState {
  data: GlobalMessage[];
}

const initialGlobalMessagesState: GlobalMessagesState = {
  data: []
};

const GlobalMessagesReducer: Reducer<GlobalMessagesState> = (
  state = initialGlobalMessagesState,
  action
) => {
  switch (action.type) {
    case ADD_GLOBAL_MESSAGE:
      return {
        ...state,
        data: [...state.data, action.payload.message]
      };
    case REMOVE_GLOBAL_MESSAGE:
      return {
        ...state,
        data: state.data.filter((message) => message.id !== action.payload.id)
      };
    default: {
      return state;
    }
  }
};

export default GlobalMessagesReducer;
