/* eslint-disable @typescript-eslint/default-param-last */
import { Product } from 'src/types/Product';

import {
  GET_PRODUCT_REQUESTED,
  GET_PRODUCT_RECEIVED,
  GET_PRODUCT_FAILURE
} from '../actions/ActionNames';

export interface ProductState {
  data: Product;
  isLoading: boolean;
}

const initialState: ProductState = {
  data: {
    id: '',
    name: '',
    hsnCode: ''
  },
  isLoading: false
};

const ProductReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PRODUCT_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default ProductReducer;
