/* eslint-disable @typescript-eslint/default-param-last */
import { Product } from 'src/types/Product';
import {
  CLEAR_PRODUCTS,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_RECEIVED,
  CREATE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_RECEIVED,
  DELETE_PRODUCT_REQUESTED,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_RECEIVED,
  GET_PRODUCTS_REQUESTED,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_RECEIVED,
  UPDATE_PRODUCT_REQUESTED
} from '../actions/ActionNames';

export interface ProductState {
  data: Product[];
  isLoading: boolean;
}

const initialState: ProductState = {
  data: [],
  isLoading: false
};

const ProductsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_PRODUCTS:
      return initialState;
    case GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PRODUCTS_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_PRODUCT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case CREATE_PRODUCT_RECEIVED: {
      const newProduct = action.payload.data;
      const newProducts = [...(state.data ?? []), newProduct];
      return {
        ...state,
        data: newProducts,
        isLoading: false
      };
    }
    case CREATE_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_PRODUCT_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_PRODUCT_RECEIVED: {
      const updatedProduct = action.payload.data;
      const newProducts = state.data.map((product) => {
        if (product.id == updatedProduct.id) {
          return updatedProduct;
        }
        return product;
      });
      return {
        ...state,
        data: newProducts,
        isLoading: false
      };
    }
    case UPDATE_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case DELETE_PRODUCT_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case DELETE_PRODUCT_RECEIVED: {
      const deletedProductId = action.payload.data.id;
      const newProducts = state.data.filter((product) => product.id !== deletedProductId);
      return {
        ...state,
        data: newProducts,
        isLoading: false
      };
    }
    case DELETE_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default ProductsReducer;
