const config = {
  development: {
    backendUrl: 'http://localhost:3000/api'
    // backendUrl: 'https://bhavani-admin-go.onrender.com/api'
  },
  production: {
    backendUrl: 'https://bhavani-admin-go.onrender.com/api'
  }
};

// Usage
const env = process.env.NODE_ENV || 'development';

export const backendUrl = config[env].backendUrl;
