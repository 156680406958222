import { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { useSelector, useDispatch } from 'react-redux';
import { OptionList } from 'src/types/OptionList';
import { getBankOptionsList } from 'src/redux/actions/BankAction';
import { getLoggedUser } from 'src/redux/actions/UserAction';
import { getCompanyOptionsList } from 'src/redux/actions/CompanyAction';
import { getCustomerOptionsList } from 'src/redux/actions/CustomerAction';
import { getPlantOptionsList } from 'src/redux/actions/PlantAction';
import { getProductOptionsList } from 'src/redux/actions/ProductAction';
import {
  getTransporterOptionsList,
  getPurchaseTransporterOptionsList
} from 'src/redux/actions/TransporterAction';
import { getVehicleOptionsList } from 'src/redux/actions/VehicleAction';
import { addErrorGlobalMessage } from 'src/redux/actions/GlobalMessagesAction';

const AuthGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();

  const AuthToken = localStorage.getItem('auth-token');
  const companyId = localStorage.getItem('company-id');

  const bankOptionsLoaded: boolean =
    useSelector((state: any) => state.bankOptions.isLoaded) || false;

  const companyOptionsLoaded =
    useSelector((state: any) => state.companyOptions.isLoaded) || false;

  const customerOptionsLoaded =
    useSelector((state: any) => state.customerOptions.isLoaded) || false;

  const plantOptionsLoaded =
    useSelector((state: any) => state.plantOptions.isLoaded) || false;

  const productOptionsLoaded =
    useSelector((state: any) => state.productOptions.isLoaded) || false;

  const transporterOptionsLoaded =
    useSelector((state: any) => state.transporterOptions.isLoaded) || false;

  const vehicleOptionsLoaded =
    useSelector((state: any) => state.vehicleOptions.isLoaded) || false;

  const purchaseTransporterOptionsLoaded =
    useSelector((state: any) => state.purchaseTransporterOptions.isLoaded) ||
    false;

  const getBankOptionsListFunction = useCallback(() => {
    if (!bankOptionsLoaded) {
      dispatch(getBankOptionsList());
    }
  }, [dispatch]);

  const getCompanyOptionsListFunction = useCallback(() => {
    if (!companyOptionsLoaded) {
      dispatch(getCompanyOptionsList());
    }
  }, [dispatch]);

  const getCustomerOptionsListFunction = useCallback(() => {
    if (!customerOptionsLoaded) {
      dispatch(getCustomerOptionsList());
    }
  }, [dispatch]);

  const getPlantOptionsListFunction = useCallback(() => {
    if (!plantOptionsLoaded) {
      dispatch(getPlantOptionsList());
    }
  }, [dispatch]);

  const getProductOptionsListFunction = useCallback(() => {
    if (!productOptionsLoaded) {
      dispatch(getProductOptionsList());
    }
  }, [dispatch]);

  const getTransporterOptionsListFunction = useCallback(() => {
    if (!transporterOptionsLoaded) {
      dispatch(getTransporterOptionsList());
    }
  }, [dispatch]);

  const getPurchaseTransporterOptionsListFunction = useCallback(() => {
    if (!purchaseTransporterOptionsLoaded) {
      dispatch(getPurchaseTransporterOptionsList());
    }
  }, [dispatch]);

  const getVehicleOptionsListFunction = useCallback(() => {
    if (!vehicleOptionsLoaded) {
      dispatch(getVehicleOptionsList());
    }
  }, [dispatch]);

  const getLoggedUserFunction = useCallback(() => {
    dispatch(getLoggedUser());
  }, [dispatch]);

  useEffect(() => {
    if (!AuthToken) {
      navigate('/signin', { replace: true });
      return;
    }
    if (
      !companyId &&
      (location.pathname.includes('invoice') ||
        location.pathname.includes('purchase'))
    ) {
      dispatch(addErrorGlobalMessage('Please select company first'));
      navigate('/system/settings', { replace: true });
    }
  }, [AuthToken, navigate, companyId]);

  useEffect(() => {
    if (AuthToken) {
      getBankOptionsListFunction();
      getCompanyOptionsListFunction();
      getCustomerOptionsListFunction();
      getPlantOptionsListFunction();
      getProductOptionsListFunction();
      getTransporterOptionsListFunction();
      getVehicleOptionsListFunction();
      getLoggedUserFunction();
      getPurchaseTransporterOptionsListFunction();
    }
  }, []);

  return children;
};

export default AuthGuard;
