/* eslint-disable @typescript-eslint/default-param-last */

import {
  SIGNIN_REQUESTED,
  SIGNIN_RECEIVED,
  SIGNIN_FAILURE
} from '../actions/ActionNames';

export interface UserState {
  signinUser: {
    email: string;
    token: string;
    userType: string;
  };
  isLoading: boolean;
}

const initialState: UserState = {
  signinUser: {
    email: '',
    token: '',
    userType: ''
  },
  isLoading: false
};

const SigninReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGNIN_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case SIGNIN_RECEIVED:
      return {
        ...state,
        signinUser: action.payload.data,
        isLoading: false
      };
    case SIGNIN_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default SigninReducer;
