import { useRoutes, Navigate } from 'react-router';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ErrorRoutes from './ErrorRoutes';
export default function Routes() {
  return useRoutes([
    { path: '/', element: <Navigate to="/dashboard" /> },
    ...LoginRoutes,
    ...MainRoutes,
    ...ErrorRoutes,
    {
      path: '*',
      element: <Navigate to="/status/404" />
    }
  ]);
}
