/* eslint-disable @typescript-eslint/default-param-last */
import {
  GET_ME_REQUESTED,
  GET_ME_RECEIVED,
  GET_ME_FAILURE,
  UPDATE_USER_REQUESTED,
  UPDATE_BANK_RECEIVED,
  UPDATE_BANK_FAILURE,
  UPDATE_USER_RECEIVED
} from '../actions/ActionNames';

const initialState = {
  loading: false,
  data: {},
  error: ''
};

const LoggedUserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ME_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case GET_ME_RECEIVED:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case GET_ME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_USER_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case UPDATE_USER_RECEIVED:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case UPDATE_USER_RECEIVED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default LoggedUserReducer;
