import * as Yup from 'yup';

export const signInFormSchema = [
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    autoComplete: 'email'
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    autoComplete: 'password'
  }
];

export const signInIntialValues = {
  email: '',
  password: ''
};

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Enter Email'),
  password: Yup.string().required('Enter Password')
});
