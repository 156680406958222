/* eslint-disable @typescript-eslint/default-param-last */
import { Plant } from 'src/types/Plant';

import {
  GET_PLANT_REQUESTED,
  GET_PLANT_RECEIVED,
  GET_PLANT_FAILURE
} from '../actions/ActionNames';

export interface PlantState {
  data: Plant;
  isLoading: boolean;
}

const initialState: PlantState = {
  data: {
    id: '',
    name: '',
    ownerName: '',
    mobile: ''
  },
  isLoading: false
};

const PlantReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PLANT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PLANT_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PLANT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default PlantReducer;
