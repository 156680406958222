import { httpAction } from './HttpAction';
import { GET_VEHICLE_OPTIONS_LIST } from './ActionNames';
import { VEHICLE_EP, DELETE, GET, POST, PUT } from 'src/constants/ApiConstants';

export const getVehicleOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_VEHICLE_OPTIONS_LIST,
        url: VEHICLE_EP + '/option-list',
        verb: GET
      })
    );
  };
};
