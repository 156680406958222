import { httpAction } from './HttpAction';
import {
  CREATE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT_OPTIONS_LIST,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  DELETE_PRODUCT
} from './ActionNames';
import { PRODUCT_EP, DELETE, GET, POST, PUT } from 'src/constants/ApiConstants';

export const getProducts = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PRODUCTS,
        url: PRODUCT_EP + '/all',
        verb: GET
      })
    );
  };
};

export const getProductOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PRODUCT_OPTIONS_LIST,
        url: PRODUCT_EP + '/option-list',
        verb: GET
      })
    );
  };
};

export const createProduct = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_PRODUCT,
        url: PRODUCT_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updateProduct = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_PRODUCT,
        url: PRODUCT_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const getProduct = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PRODUCT,
        url: PRODUCT_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const deletProduct = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: DELETE_PRODUCT,
        url: PRODUCT_EP + '/delete/' + id,
        verb: DELETE
      })
    );
  };
};
