/* eslint-disable @typescript-eslint/default-param-last */
import { Customer } from 'src/types/Customer';

import {
  GET_CUSTOMERS_REQUESTED,
  GET_CUSTOMERS_RECEIVED,
  GET_CUSTOMERS_FAILURE,
  CREATE_CUSTOMER_REQUESTED,
  CREATE_CUSTOMER_RECEIVED,
  CREATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUESTED,
  UPDATE_CUSTOMER_RECEIVED,
  UPDATE_CUSTOMER_FAILURE
} from '../actions/ActionNames';

export interface CustomersState {
  data: Customer[];
  isLoading: boolean;
}

const initialState: CustomersState = {
  data: [],
  isLoading: false
};

const CustomersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_CUSTOMERS_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_CUSTOMER_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_CUSTOMER_RECEIVED: {
      return {
        ...state,
        data: [...(state.data ?? []), action.payload.data],
        isLoading: false
      };
    }
    case CREATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_CUSTOMER_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_CUSTOMER_RECEIVED: {
      return {
        ...state,
        data: state.data.map((customer: Customer) => {
          if (customer.id === action.payload.data.id) {
            return action.payload.data;
          }
          return customer;
        }),
        isLoading: false
      };
    }
    case UPDATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default CustomersReducer;
