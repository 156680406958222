import { configureStore } from '@reduxjs/toolkit';

// middlewares
import { httpMiddleware } from '../middlewares/HttpMiddleware';

// reducers
import BanksReducer from '../reducers/BanksReducer';
import BankReducer from '../reducers/BankReducer';

import CompaniesReducer from '../reducers/CompaniesReducer';
import CompanyReducer from '../reducers/CompanyReducer';

import CustomerReducer from '../reducers/CustomerReducer';
import CustomersReducer from '../reducers/CustomersReducer';

import PlantReducer from '../reducers/PlantReducer';
import PlantsReducer from '../reducers/PlantsReducer';

import ProductReducer from '../reducers/ProductReducer';
import ProductsReducer from '../reducers/ProductsReducer';

import TransporterReducer from '../reducers/TransporterReducer';
import TransportersReducer from '../reducers/TransportersReducer';

import PurchasesReducer from '../reducers/PurchasesReducer';
import PurchaseReducer from '../reducers/PurchaseReducer';

import InvoiceReducer from '../reducers/InvoiceReducer';
import InvoicesReducer from '../reducers/InvoicesReducer';

import BankOptionsReducer from '../reducers/BankOptionsReducer';
import CompanyOptionsReducer from '../reducers/CompanyOptionsReducer';
import CustomerOptionsReducer from '../reducers/CustomerOptionsReducer';
import PlantOptionsReducer from '../reducers/PlantOptionsReducer';
import ProductOptionsReducer from '../reducers/ProductOptionsReducer';
import VehicleOptionsReducer from '../reducers/VehicleOptionsReducer';
import TransporterOptionsReducer from '../reducers/TransporterOptionsReducer';
import PurchaseTransporterOptionsReducer from '../reducers/PurchaseTransporterOptionsReducer';

import LoggedUserReducer from '../reducers/LoggedUserReducer';

import SigninReducer from '../reducers/SigninReducer';
import SignupReducer from '../reducers/SignupReducer';

import GlobalMessagesReducer from '../reducers/GlobalMessageReducer';

import BulkPurchaseReducer from '../reducers/BulkPurchaseReducer';

export default configureStore({
  reducer: {
    banks: BanksReducer,
    bank: BankReducer,

    companies: CompaniesReducer,
    company: CompanyReducer,

    customer: CustomerReducer,
    customers: CustomersReducer,

    transporter: TransporterReducer,
    transporters: TransportersReducer,

    plant: PlantReducer,
    plants: PlantsReducer,

    product: ProductReducer,
    products: ProductsReducer,

    purchases: PurchasesReducer,
    purchase: PurchaseReducer,

    invoice: InvoiceReducer,
    invoices: InvoicesReducer,

    bankOptions: BankOptionsReducer,
    companyOptions: CompanyOptionsReducer,
    customerOptions: CustomerOptionsReducer,
    plantOptions: PlantOptionsReducer,
    productOptions: ProductOptionsReducer,
    transporterOptions: TransporterOptionsReducer,
    purchaseTransporterOptions: PurchaseTransporterOptionsReducer,
    vehicleOptions: VehicleOptionsReducer,

    signin: SigninReducer,
    signup: SignupReducer,

    loggedUser: LoggedUserReducer,
    globalMessages: GlobalMessagesReducer,
    bulkPurchase: BulkPurchaseReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(httpMiddleware)
});
