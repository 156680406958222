/* eslint-disable @typescript-eslint/default-param-last */
import { OptionList } from 'src/types/OptionList';
import {
  GET_PLANT_OPTIONS_LIST_REQUESTED,
  GET_PLANT_OPTIONS_LIST_RECEIVED,
  GET_PLANT_OPTIONS_LIST_FAILURE,
  CLEAR_PLANT_OPTIONS_LIST
} from '../actions/ActionNames';

export interface PlantOptionState {
  data: OptionList[];
  isLoading: boolean;
  isLoaded?: boolean;
}

const initialState: PlantOptionState = {
  data: [],
  isLoading: false,
  isLoaded: false
};

const PlantOptionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_PLANT_OPTIONS_LIST:
      return initialState;
    case GET_PLANT_OPTIONS_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PLANT_OPTIONS_LIST_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true
      };
    case GET_PLANT_OPTIONS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default PlantOptionsReducer;
