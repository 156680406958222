import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const ErrorRoutes = [
  { path: '/status/404', element: <Status404 /> },
  { path: '/status/500', element: <Status500 /> },
  { path: '/status/coming-soon', element: <StatusComingSoon /> },
  { path: '/status/maintenance', element: <StatusMaintenance /> }
];

export default ErrorRoutes;
