/* eslint-disable @typescript-eslint/default-param-last */
import { Bank } from 'src/types/Bank';
import {
  CLEAR_BANKS,
  CREATE_BANK_FAILURE,
  CREATE_BANK_RECEIVED,
  CREATE_BANK_REQUESTED,
  DELETE_BANK_FAILURE,
  DELETE_BANK_RECEIVED,
  DELETE_BANK_REQUESTED,
  GET_BANKS_FAILURE,
  GET_BANKS_RECEIVED,
  GET_BANKS_REQUESTED,
  UPDATE_BANK_FAILURE,
  UPDATE_BANK_RECEIVED,
  UPDATE_BANK_REQUESTED
} from '../actions/ActionNames';

export interface BankState {
  data: Bank[];
  isLoading: boolean;
}

const initialState: BankState = {
  data: [],
  isLoading: false
};

const BanksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_BANKS:
      return initialState;
    case GET_BANKS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_BANKS_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_BANKS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_BANK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case CREATE_BANK_RECEIVED: {
      const newBank = action.payload.data;
      const newBanks = [...(state.data ?? []), newBank];
      return {
        ...state,
        data: newBanks,
        isLoading: false
      };
    }
    case CREATE_BANK_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_BANK_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_BANK_RECEIVED: {
      const updatedBank = action.payload.data;
      const newBanks = state.data.map((bank) => {
        if (bank.id == updatedBank.id) {
          return updatedBank;
        }
        return bank;
      });
      return {
        ...state,
        data: newBanks,
        isLoading: false
      };
    }
    case UPDATE_BANK_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case DELETE_BANK_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case DELETE_BANK_RECEIVED: {
      const deletedBankId = action.payload.data.id;
      const newBanks = state.data.filter((bank) => bank.id !== deletedBankId);
      return {
        ...state,
        data: newBanks,
        isLoading: false
      };
    }
    case DELETE_BANK_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default BanksReducer;
