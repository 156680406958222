/* eslint-disable @typescript-eslint/default-param-last */

import {
  SIGNUP_REQUESTED,
  SIGNUP_RECEIVED,
  SIGNUP_FAILURE
} from '../actions/ActionNames';

export interface SignupState {
  signupUser: {
    firstName: string;
    lastName: string;
    email: string;
  };
  isLoading: boolean;
}

const initialState: SignupState = {
  signupUser: {
    firstName: '',
    lastName: '',
    email: ''
  },
  isLoading: false
};

const SignupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGNUP_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case SIGNUP_RECEIVED:
      return {
        ...state,
        signupUser: action.payload.data,
        isLoading: false
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default SignupReducer;
