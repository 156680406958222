import * as Yup from 'yup';

export const signUpFormSchema = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    autoComplete: 'First Name'
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    autoComplete: 'Last Name'
  },
  {
    name: 'inviteCode',
    type: 'text',
    label: 'Invite Code',
    autoComplete: 'Invite Code'
  },
  {
    name: 'mobile',
    type: 'text',
    label: 'Mobile',
    autoComplete: 'Mobile'
  },
  {
    name: 'email',
    type: 'text',
    label: 'Email',
    autoComplete: 'email'
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    autoComplete: 'password'
  }
];

export const signUpIntialValues = {
  firstName: '',
  lastName: '',
  inviteCode: '',
  mobile: '',
  email: '',
  password: ''
};

export const signUpValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Enter First Name'),
  lastName: Yup.string().required('Enter Last Name'),
  inviteCode: Yup.string().required('Enter Invite Code'),
  mobile: Yup.string().length(10, 'Invalid Mobile').required('Enter Mobile'),
  email: Yup.string().email('Invalid email address').required('Enter Email'),
  password: Yup.string().required('Enter Password')
});
