/* eslint-disable @typescript-eslint/default-param-last */
import { Plant } from 'src/types/Plant';
import {
  CLEAR_PLANTS,
  CREATE_PLANT_FAILURE,
  CREATE_PLANT_RECEIVED,
  CREATE_PLANT_REQUESTED,
  DELETE_PLANT_FAILURE,
  DELETE_PLANT_RECEIVED,
  DELETE_PLANT_REQUESTED,
  GET_PLANTS_FAILURE,
  GET_PLANTS_RECEIVED,
  GET_PLANTS_REQUESTED,
  UPDATE_PLANT_FAILURE,
  UPDATE_PLANT_RECEIVED,
  UPDATE_PLANT_REQUESTED
} from '../actions/ActionNames';

export interface PlantState {
  data: Plant[];
  isLoading: boolean;
}

const initialState: PlantState = {
  data: [],
  isLoading: false
};

const PlantsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_PLANTS:
      return initialState;
    case GET_PLANTS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PLANTS_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PLANTS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_PLANT_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case CREATE_PLANT_RECEIVED: {
      const newPlant = action.payload.data;
      const newPlants = [...(state.data ?? []), newPlant];
      return {
        ...state,
        data: newPlants,
        isLoading: false
      };
    }
    case CREATE_PLANT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_PLANT_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_PLANT_RECEIVED: {
      const updatedPlant = action.payload.data;
      const newPlants = state.data.map((plant) => {
        if (plant.id == updatedPlant.id) {
          return updatedPlant;
        }
        return plant;
      });
      return {
        ...state,
        data: newPlants,
        isLoading: false
      };
    }
    case UPDATE_PLANT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case DELETE_PLANT_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case DELETE_PLANT_RECEIVED: {
      const deletedPlantId = action.payload.data.id;
      const newPlants = state.data.filter((plant) => plant.id !== deletedPlantId);
      return {
        ...state,
        data: newPlants,
        isLoading: false
      };
    }
    case DELETE_PLANT_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default PlantsReducer;
