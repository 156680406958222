import { EMPTY_STRING, STRING } from 'src/constants/AppConstants';

import { formDate, unixDate } from './dateUtils';
export const getString = (someString: string) => {
  return typeof someString === STRING ? someString : EMPTY_STRING;
};

export const checkForStringEqualityIgnoreCase = (
  string1: string,
  string2: string
): boolean => {
  return getString(string1).toLowerCase() === getString(string2).toLowerCase();
};

export const isValidToken = (token: any): boolean => {
  return typeof token === STRING && token.length > 0;
};

export const getDataForTransmit = (data: any): any => {
  Object.keys(data).forEach((key) => {
    if (
      typeof data[key] === 'undefined' ||
      data[key] === null ||
      data[key] === EMPTY_STRING
    ) {
      delete data[key];
    } else if (key.toLowerCase().includes('date')) {
      data[key] = formDate(data[key]);
    }
  });
  return data;
};

export const formatResponseData = (data: any): any => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'undefined') {
      data[key] = EMPTY_STRING;
    } else if (key.toLowerCase().includes('date')) {
      data[key] = unixDate(data[key]);
    }
  });

  return data;
};
