/* eslint-disable @typescript-eslint/default-param-last */
import { Purchase } from 'src/types/Purchase';
import {
  CLEAR_PURCHASES,
  DELETE_PURCHASE_FAILURE,
  DELETE_PURCHASE_RECEIVED,
  DELETE_PURCHASE_REQUESTED,
  GET_PURCHASES_FAILURE,
  GET_PURCHASES_RECEIVED,
  GET_PURCHASES_REQUESTED,
  UPDATE_PURCHASE_FAILURE,
  UPDATE_PURCHASE_RECEIVED,
  UPDATE_PURCHASE_REQUESTED
} from '../actions/ActionNames';

export interface PurchaseState {
  data: any[];
  isLoading: boolean;
}

const initialState: PurchaseState = {
  data: [],
  isLoading: false
};

const PurchasesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_PURCHASES:
      return initialState;
    case GET_PURCHASES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PURCHASES_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_PURCHASES_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_PURCHASE_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_PURCHASE_RECEIVED: {
      const updatedPurchase = action.payload.data;
      const newPurchases = state.data.map((purchase) => {
        if (purchase.id == updatedPurchase.id) {
          return updatedPurchase;
        }
        return purchase;
      });
      return {
        ...state,
        data: newPurchases,
        isLoading: false
      };
    }
    case UPDATE_PURCHASE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case DELETE_PURCHASE_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case DELETE_PURCHASE_RECEIVED: {
      const deletedPurchaseId = action.payload.data.id;
      const newPurchases = state.data.filter(
        (purchase) => purchase.id !== deletedPurchaseId
      );
      return {
        ...state,
        data: newPurchases,
        isLoading: false
      };
    }
    case DELETE_PURCHASE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default PurchasesReducer;
