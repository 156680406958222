/* eslint-disable @typescript-eslint/default-param-last */
import {
  ADD_PURCHASE_TO_BULK,
  REMOVE_PURCHASE_FROM_BULK,
  RESET_BULK_PURCHASE
} from '../actions/ActionNames';
import { Purchase } from 'src/types/Purchase';

export interface BulkPurchaseState {
  data: any[];
  total: number;
}

const initialState: BulkPurchaseState = {
  data: [],
  total: 0
};

const BulkPurchaseReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_PURCHASE_TO_BULK:
      return {
        ...state,
        data: [...(state.data || []), action.payload],
        total: state.total + 1
      };

    case REMOVE_PURCHASE_FROM_BULK:
      return {
        ...state,
        data:
          state?.data?.filter(
            (purchase: any) => purchase.id !== action.payload.id
          ) || [],
        total: state.total - 1
      };
    case RESET_BULK_PURCHASE:
      return {
        initialState
      };
    default:
      return state;
  }
};

export default BulkPurchaseReducer;
