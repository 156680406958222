import Signin from 'src/bhavaniComponents/Signin';
import SignUp from 'src/bhavaniComponents/Signup';

const LoginRoutes = [
  {
    path: '/signin',
    element: <Signin />
  },
  {
    path: '/signup',
    element: <SignUp />
  }
];

export default LoginRoutes;
