import { ADD_GLOBAL_MESSAGE, REMOVE_GLOBAL_MESSAGE } from './ActionNames';
import { getString } from 'src/utils/appUtils';
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_INFORMATIONAL,
  ALERT_TYPE_SUCCESS,
  ALERT_TYPE_WARNING,
  EMPTY_STRING
} from '../../constants/AppConstants';
import { v4 as uuidv4 } from 'uuid';
import { GlobalMessage } from 'src/types/GlobalMessage';
import { globalMessagesConfigUtil } from 'src/utils/globalMessageUtils';

export const addGlobalMessage = (message: GlobalMessage) => {
  return (dispatch: any) => {
    const messageId = uuidv4();
    /* Auto Remove the message after 10 seconds*/
    setTimeout(() => {
      dispatch(removeGlobalMessage(messageId));
    }, globalMessagesConfigUtil.messageAutoDeleteTime);
    dispatch({
      type: ADD_GLOBAL_MESSAGE,
      payload: {
        message: {
          ...message,
          id: messageId
        }
      }
    });
  };
};

export const addSuccessGlobalMessage = (messageText: string) => {
  const message: GlobalMessage = {
    id: EMPTY_STRING,
    type: ALERT_TYPE_SUCCESS,
    text: getString(messageText)
  };
  return (dispatch: any) => {
    dispatch(addGlobalMessage(message));
  };
};

export const addErrorGlobalMessage = (messageText: string) => {
  const message: GlobalMessage = {
    id: EMPTY_STRING,
    type: ALERT_TYPE_ERROR,
    text: getString(messageText)
  };
  return (dispatch: any) => {
    dispatch(addGlobalMessage(message));
  };
};

export const addWarningGlobalMessage = (messageText: string) => {
  const message: GlobalMessage = {
    id: EMPTY_STRING,
    type: ALERT_TYPE_WARNING,
    text: getString(messageText)
  };
  return (dispatch: any) => {
    dispatch(addGlobalMessage(message));
  };
};

export const addInformationalGlobalMessage = (messageText: string) => {
  const message: GlobalMessage = {
    id: EMPTY_STRING,
    type: ALERT_TYPE_INFORMATIONAL,
    text: getString(messageText)
  };
  return (dispatch: any) => {
    dispatch(addGlobalMessage(message));
  };
};

export const removeGlobalMessage = (messageId: string) => {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_GLOBAL_MESSAGE,
      payload: {
        id: getString(messageId)
      }
    });
  };
};

/** Since next()/store.dispatch() in HttpMiddleware only accepts object and not a function, the logic to add error
 global message is written separately for httpMiddleware */
export const addErrorGlobalMessageForHttpMiddleware = (
  dispatch: any,
  errorMessage: string
) => {
  const messageId = uuidv4();
  setTimeout(() => {
    dispatch({
      type: REMOVE_GLOBAL_MESSAGE,
      payload: {
        id: getString(messageId)
      }
    });
  }, globalMessagesConfigUtil.messageAutoDeleteTime);
  dispatch({
    type: ADD_GLOBAL_MESSAGE,
    payload: {
      message: {
        id: messageId,
        type: ALERT_TYPE_ERROR,
        text: errorMessage
      }
    }
  });
};
