export const HTTP_ACTION = 'HTTP_ACTION';

export const ADD_GLOBAL_MESSAGE = 'ADD_GLOBAL_MESSAGE';
export const REMOVE_GLOBAL_MESSAGE = 'REMOVE_GLOBAL_MESSAGE';

export const GET_BANKS = 'GET_BANKS';
export const GET_BANKS_REQUESTED = 'GET_BANKS_REQUESTED';
export const GET_BANKS_RECEIVED = 'GET_BANKS_RECEIVED';
export const GET_BANKS_FAILURE = 'GET_BANKS_FAILURE';
export const CLEAR_BANKS = 'CLEAR_BANKS';

export const GET_BANK = 'GET_BANK';
export const GET_BANK_REQUESTED = 'GET_BANK_REQUESTED';
export const GET_BANK_RECEIVED = 'GET_BANK_RECEIVED';
export const GET_BANK_FAILURE = 'GET_BANK_FAILURE';

export const CREATE_BANK = 'CREATE_BANK';
export const CREATE_BANK_REQUESTED = 'CREATE_BANK_REQUESTED';
export const CREATE_BANK_RECEIVED = 'CREATE_BANK_RECEIVED';
export const CREATE_BANK_FAILURE = 'CREATE_BANK_FAILURE';

export const UPDATE_BANK = 'UPDATE_BANK';
export const UPDATE_BANK_REQUESTED = 'UPDATE_BANK_REQUESTED';
export const UPDATE_BANK_RECEIVED = 'UPDATE_BANK_RECEIVED';
export const UPDATE_BANK_FAILURE = 'UPDATE_BANK_FAILURE';

export const DELETE_BANK = 'DELETE_BANK';
export const DELETE_BANK_REQUESTED = 'DELETE_BANK_REQUESTED';
export const DELETE_BANK_RECEIVED = 'DELETE_BANK_RECEIVED';
export const DELETE_BANK_FAILURE = 'DELETE_BANK_FAILURE';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_REQUESTED = 'SIGNUP_REQUESTED';
export const SIGNUP_RECEIVED = 'SIGNUP_RECEIVED';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED';
export const SIGNIN_RECEIVED = 'SIGNIN_RECEIVED';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

export const GET_ME = 'GET_ME';
export const GET_ME_REQUESTED = 'GET_ME_REQUESTED';
export const GET_ME_RECEIVED = 'GET_ME_RECEIVED';
export const GET_ME_FAILURE = 'GET_ME_FAILURE';

export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_REQUESTED = 'GET_COMPANIES_REQUESTED';
export const GET_COMPANIES_RECEIVED = 'GET_COMPANIES_RECEIVED';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_REQUESTED = 'GET_COMPANY_REQUESTED';
export const GET_COMPANY_RECEIVED = 'GET_COMPANY_RECEIVED';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_REQUESTED = 'CREATE_COMPANY_REQUESTED';
export const CREATE_COMPANY_RECEIVED = 'CREATE_COMPANY_RECEIVED';
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_REQUESTED = 'UPDATE_COMPANY_REQUESTED';
export const UPDATE_COMPANY_RECEIVED = 'UPDATE_COMPANY_RECEIVED';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_REQUESTED = 'DELETE_COMPANY_REQUESTED';
export const DELETE_COMPANY_RECEIVED = 'DELETE_COMPANY_RECEIVED';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_REQUESTED = 'GET_CUSTOMERS_REQUESTED';
export const GET_CUSTOMERS_RECEIVED = 'GET_CUSTOMERS_RECEIVED';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_REQUESTED = 'GET_CUSTOMER_REQUESTED';
export const GET_CUSTOMER_RECEIVED = 'GET_CUSTOMER_RECEIVED';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_REQUESTED = 'UPDATE_CUSTOMER_REQUESTED';
export const UPDATE_CUSTOMER_RECEIVED = 'UPDATE_CUSTOMER_RECEIVED';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_REQUESTED = 'CREATE_CUSTOMER_REQUESTED';
export const CREATE_CUSTOMER_RECEIVED = 'CREATE_CUSTOMER_RECEIVED';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_REQUESTED = 'DELETE_CUSTOMER_REQUESTED';
export const DELETE_CUSTOMER_RECEIVED = 'DELETE_CUSTOMER_RECEIVED';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const GET_PLANTS = 'GET_PLANTS';
export const GET_PLANTS_REQUESTED = 'GET_PLANTS_REQUESTED';
export const GET_PLANTS_RECEIVED = 'GET_PLANTS_RECEIVED';
export const GET_PLANTS_FAILURE = 'GET_PLANTS_FAILURE';
export const CLEAR_PLANTS = 'CLEAR_PLANTS';

export const GET_PLANT = 'GET_PLANT';
export const GET_PLANT_REQUESTED = 'GET_PLANT_REQUESTED';
export const GET_PLANT_RECEIVED = 'GET_PLANT_RECEIVED';
export const GET_PLANT_FAILURE = 'GET_PLANT_FAILURE';

export const UPDATE_PLANT = 'UPDATE_PLANT';
export const UPDATE_PLANT_REQUESTED = 'UPDATE_PLANT_REQUESTED';
export const UPDATE_PLANT_RECEIVED = 'UPDATE_PLANT_RECEIVED';
export const UPDATE_PLANT_FAILURE = 'UPDATE_PLANT_FAILURE';

export const CREATE_PLANT = 'CREATE_PLANT';
export const CREATE_PLANT_REQUESTED = 'CREATE_PLANT_REQUESTED';
export const CREATE_PLANT_RECEIVED = 'CREATE_PLANT_RECEIVED';
export const CREATE_PLANT_FAILURE = 'CREATE_PLANT_FAILURE';

export const DELETE_PLANT = 'DELETE_PLANT';
export const DELETE_PLANT_REQUESTED = 'DELETE_PLANT_REQUESTED';
export const DELETE_PLANT_RECEIVED = 'DELETE_PLANT_RECEIVED';
export const DELETE_PLANT_FAILURE = 'DELETE_PLANT_FAILURE';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_REQUESTED = 'GET_PRODUCTS_REQUESTED';
export const GET_PRODUCTS_RECEIVED = 'GET_PRODUCTS_RECEIVED';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_REQUESTED = 'GET_PRODUCT_REQUESTED';
export const GET_PRODUCT_RECEIVED = 'GET_PRODUCT_RECEIVED';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_RECEIVED = 'UPDATE_PRODUCT_RECEIVED';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_REQUESTED = 'CREATE_PRODUCT_REQUESTED';
export const CREATE_PRODUCT_RECEIVED = 'CREATE_PRODUCT_RECEIVED';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_RECEIVED = 'DELETE_PRODUCT_RECEIVED';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const GET_TRANSPORTERS = 'GET_TRANSPORTERS';
export const GET_TRANSPORTERS_REQUESTED = 'GET_TRANSPORTERS_REQUESTED';
export const GET_TRANSPORTERS_RECEIVED = 'GET_TRANSPORTERS_RECEIVED';
export const GET_TRANSPORTERS_FAILURE = 'GET_TRANSPORTERS_FAILURE';

export const GET_TRANSPORTER = 'GET_TRANSPORTER';
export const GET_TRANSPORTER_REQUESTED = 'GET_TRANSPORTER_REQUESTED';
export const GET_TRANSPORTER_RECEIVED = 'GET_TRANSPORTER_RECEIVED';
export const GET_TRANSPORTER_FAILURE = 'GET_TRANSPORTER_FAILURE';

export const UPDATE_TRANSPORTER = 'UPDATE_TRANSPORTER';
export const UPDATE_TRANSPORTER_REQUESTED = 'UPDATE_TRANSPORTER_REQUESTED';
export const UPDATE_TRANSPORTER_RECEIVED = 'UPDATE_TRANSPORTER_RECEIVED';
export const UPDATE_TRANSPORTER_FAILURE = 'UPDATE_TRANSPORTER_FAILURE';

export const CREATE_TRANSPORTER = 'CREATE_TRANSPORTER';
export const CREATE_TRANSPORTER_REQUESTED = 'CREATE_TRANSPORTER_REQUESTED';
export const CREATE_TRANSPORTER_RECEIVED = 'CREATE_TRANSPORTER_RECEIVED';
export const CREATE_TRANSPORTER_FAILURE = 'CREATE_TRANSPORTER_FAILURE';

export const DELETE_TRANSPORTER = 'DELETE_TRANSPORTER';
export const DELETE_TRANSPORTER_REQUESTED = 'DELETE_TRANSPORTER_REQUESTED';
export const DELETE_TRANSPORTER_RECEIVED = 'DELETE_TRANSPORTER_RECEIVED';
export const DELETE_TRANSPORTER_FAILURE = 'DELETE_TRANSPORTER_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_RECEIVED = 'UPDATE_USER_RECEIVED';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// OPTIONS LIST

export const GET_BANK_OPTIONS_LIST = 'GET_BANK_OPTIONS_LIST';
export const GET_BANK_OPTIONS_LIST_REQUESTED =
  'GET_BANK_OPTIONS_LIST_REQUESTED';
export const GET_BANK_OPTIONS_LIST_RECEIVED = 'GET_BANK_OPTIONS_LIST_RECEIVED';
export const GET_BANK_OPTIONS_LIST_FAILURE = 'GET_BANK_OPTIONS_LIST_FAILURE';
export const CLEAR_BANK_OPTIONS_LIST = 'CLEAR_BANK_OPTIONS_LIST';

export const GET_COMPANY_OPTIONS_LIST = 'GET_COMPANY_OPTIONS_LIST';
export const GET_COMPANY_OPTIONS_LIST_REQUESTED =
  'GET_COMPANY_OPTIONS_LIST_REQUESTED';
export const GET_COMPANY_OPTIONS_LIST_RECEIVED =
  'GET_COMPANY_OPTIONS_LIST_RECEIVED';
export const GET_COMPANY_OPTIONS_LIST_FAILURE =
  'GET_COMPANY_OPTIONS_LIST_FAILURE';
export const CLEAR_COMPANY_OPTIONS_LIST = 'CLEAR_COMPANY_OPTIONS_LIST';

export const GET_PLANT_OPTIONS_LIST = 'GET_PLANT_OPTIONS_LIST';
export const GET_PLANT_OPTIONS_LIST_REQUESTED =
  'GET_PLANT_OPTIONS_LIST_REQUESTED';
export const GET_PLANT_OPTIONS_LIST_RECEIVED =
  'GET_PLANT_OPTIONS_LIST_RECEIVED';
export const GET_PLANT_OPTIONS_LIST_FAILURE = 'GET_PLANT_OPTIONS_LIST_FAILURE';
export const CLEAR_PLANT_OPTIONS_LIST = 'CLEAR_PLANT_OPTIONS_LIST';

export const GET_PRODUCT_OPTIONS_LIST = 'GET_PRODUCT_OPTIONS_LIST';
export const GET_PRODUCT_OPTIONS_LIST_REQUESTED =
  'GET_PRODUCT_OPTIONS_LIST_REQUESTED';
export const GET_PRODUCT_OPTIONS_LIST_RECEIVED =
  'GET_PRODUCT_OPTIONS_LIST_RECEIVED';
export const GET_PRODUCT_OPTIONS_LIST_FAILURE =
  'GET_PRODUCT_OPTIONS_LIST_FAILURE';
export const CLEAR_PRODUCT_OPTIONS_LIST = 'CLEAR_PRODUCT_OPTIONS_LIST';

export const GET_CUSTOMER_OPTIONS_LIST = 'GET_CUSTOMER_OPTIONS_LIST';
export const GET_CUSTOMER_OPTIONS_LIST_REQUESTED =
  'GET_CUSTOMER_OPTIONS_LIST_REQUESTED';
export const GET_CUSTOMER_OPTIONS_LIST_RECEIVED =
  'GET_CUSTOMER_OPTIONS_LIST_RECEIVED';
export const GET_CUSTOMER_OPTIONS_LIST_FAILURE =
  'GET_CUSTOMER_OPTIONS_LIST_FAILURE';
export const CLEAR_CUSTOMER_OPTIONS_LIST = 'CLEAR_CUSTOMER_OPTIONS_LIST';

export const GET_TRANSPORTER_OPTIONS_LIST = 'GET_TRANSPORTER_OPTIONS_LIST';
export const GET_TRANSPORTER_OPTIONS_LIST_REQUESTED =
  'GET_TRANSPORTER_OPTIONS_LIST_REQUESTED';
export const GET_TRANSPORTER_OPTIONS_LIST_RECEIVED =
  'GET_TRANSPORTER_OPTIONS_LIST_RECEIVED';
export const GET_TRANSPORTER_OPTIONS_LIST_FAILURE =
  'GET_TRANSPORTER_OPTIONS_LIST_FAILURE';
export const CLEAR_TRANSPORTER_OPTIONS_LIST = 'CLEAR_TRANSPORTER_OPTIONS_LIST';

export const GET_PURCHASE_TRANSPORTER_OPTIONS_LIST =
  'GET_PURCHASE_TRANSPORTER_OPTIONS_LIST';
export const GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_REQUESTED =
  'GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_REQUESTED';
export const GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_RECEIVED =
  'GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_RECEIVED';
export const GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_FAILURE =
  'GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_FAILURE';
export const CLEAR_PURCHASE_TRANSPORTER_OPTIONS_LIST =
  'CLEAR_PURCHASE_TRANSPORTER_OPTIONS_LIST';

export const GET_VEHICLE_OPTIONS_LIST = 'GET_VEHICLE_OPTIONS_LIST';
export const GET_VEHICLE_OPTIONS_LIST_REQUESTED =
  'GET_VEHICLE_OPTIONS_LIST_REQUESTED';
export const GET_VEHICLE_OPTIONS_LIST_RECEIVED =
  'GET_VEHICLE_OPTIONS_LIST_RECEIVED';
export const GET_VEHICLE_OPTIONS_LIST_FAILURE =
  'GET_VEHICLE_OPTIONS_LIST_FAILURE';
export const CLEAR_VEHICLE_OPTIONS_LIST = 'CLEAR_VEHICLE_OPTIONS_LIST';

export const ADD_PURCHASE_TO_BULK = 'ADD_PURCHASE_TO_BULK';

export const REMOVE_PURCHASE_FROM_BULK = 'REMOVE_PURCHASE_FROM_BULK';

export const RESET_BULK_PURCHASE = 'RESET_BULK_PURCHASE';

export const BULK_PURCHASE = 'BULK_PURCHASE';
export const BULK_PURCHASE_REQUESTED = 'BULK_PURCHASE_REQUESTED';
export const BULK_PURCHASE_RECEIVED = 'BULK_PURCHASE_RECEIVED';
export const BULK_PURCHASE_FAILURE = 'BULK_PURCHASE_FAILURE';

export const GET_PURCHASES = 'GET_PURCHASES';
export const GET_PURCHASES_REQUESTED = 'GET_PURCHASES_REQUESTED';
export const GET_PURCHASES_RECEIVED = 'GET_PURCHASES_RECEIVED';
export const GET_PURCHASES_FAILURE = 'GET_PURCHASES_FAILURE';
export const CLEAR_PURCHASES = 'CLEAR_PURCHASES';

export const GET_PURCHASE = 'GET_PURCHASE';
export const GET_PURCHASE_REQUESTED = 'GET_PURCHASE_REQUESTED';
export const GET_PURCHASE_RECEIVED = 'GET_PURCHASE_RECEIVED';
export const GET_PURCHASE_FAILURE = 'GET_PURCHASE_FAILURE';

export const CREATE_PURCHASE = 'CREATE_PURCHASE';
export const CREATE_PURCHASE_REQUESTED = 'CREATE_PURCHASE_REQUESTED';
export const CREATE_PURCHASE_RECEIVED = 'CREATE_PURCHASE_RECEIVED';
export const CREATE_PURCHASE_FAILURE = 'CREATE_PURCHASE_FAILURE';

export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const UPDATE_PURCHASE_REQUESTED = 'UPDATE_PURCHASE_REQUESTED';
export const UPDATE_PURCHASE_RECEIVED = 'UPDATE_PURCHASE_RECEIVED';
export const UPDATE_PURCHASE_FAILURE = 'UPDATE_PURCHASE_FAILURE';

export const DELETE_PURCHASE = 'DELETE_PURCHASE';
export const DELETE_PURCHASE_REQUESTED = 'DELETE_PURCHASE_REQUESTED';
export const DELETE_PURCHASE_RECEIVED = 'DELETE_PURCHASE_RECEIVED';
export const DELETE_PURCHASE_FAILURE = 'DELETE_PURCHASE_FAILURE';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_REQUESTED = 'GET_INVOICES_REQUESTED';
export const GET_INVOICES_RECEIVED = 'GET_INVOICES_RECEIVED';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';
export const CLEAR_INVOICES = 'CLEAR_INVOICES';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_REQUESTED = 'GET_INVOICE_REQUESTED';
export const GET_INVOICE_RECEIVED = 'GET_INVOICE_RECEIVED';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_REQUESTED = 'CREATE_INVOICE_REQUESTED';
export const CREATE_INVOICE_RECEIVED = 'CREATE_INVOICE_RECEIVED';
export const CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE';

export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_REQUESTED = 'UPDATE_INVOICE_REQUESTED';
export const UPDATE_INVOICE_RECEIVED = 'UPDATE_INVOICE_RECEIVED';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_REQUESTED = 'DELETE_INVOICE_REQUESTED';
export const DELETE_INVOICE_RECEIVED = 'DELETE_INVOICE_RECEIVED';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';
