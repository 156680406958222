import { httpAction } from './HttpAction';
import { SIGNIN, SIGNUP, GET_ME, UPDATE_USER } from './ActionNames';

import { GET, POST, PUT, USER_EP } from 'src/constants/ApiConstants';

export const signIn = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: SIGNIN,
        url: USER_EP + '/signin',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const signUp = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: SIGNUP,
        url: USER_EP + '/signup',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const getLoggedUser = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_ME,
        url: USER_EP + '/me',
        verb: GET
      })
    );
  };
};

export const updateUser = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_USER,
        url: USER_EP + '/me',
        verb: PUT,
        payload: payload
      })
    );
  };
};
