/* eslint-disable @typescript-eslint/default-param-last */
import { Company } from 'src/types/Company';

import {
  GET_COMPANIES_REQUESTED,
  GET_COMPANIES_RECEIVED,
  GET_COMPANIES_FAILURE,
  CREATE_COMPANY_REQUESTED,
  CREATE_COMPANY_RECEIVED,
  CREATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUESTED,
  UPDATE_COMPANY_RECEIVED,
  UPDATE_COMPANY_FAILURE
} from '../actions/ActionNames';

export interface CompaniesState {
  data: Company[];
  isLoading: boolean;
}

const initialState: CompaniesState = {
  data: [],
  isLoading: false
};

const CompaniesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_COMPANIES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_COMPANIES_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_COMPANIES_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_COMPANY_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_COMPANY_RECEIVED: {
      return {
        ...state,
        data: [...(state.data ?? []), action.payload.data],
        isLoading: false
      };
    }
    case CREATE_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_COMPANY_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_COMPANY_RECEIVED: {
      const updatedCompany = action.payload.data;
      const updatedCompanies = state.data.map((company) => {
        if (company.id === updatedCompany.id) {
          return updatedCompany;
        }
        return company;
      });
      return {
        ...state,
        data: updatedCompanies,
        isLoading: false
      };
    }
    case UPDATE_COMPANY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default CompaniesReducer;
