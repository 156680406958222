/* eslint-disable @typescript-eslint/default-param-last */
import { OptionList } from 'src/types/OptionList';
import {
  GET_VEHICLE_OPTIONS_LIST_REQUESTED,
  GET_VEHICLE_OPTIONS_LIST_RECEIVED,
  GET_VEHICLE_OPTIONS_LIST_FAILURE,
  CLEAR_VEHICLE_OPTIONS_LIST
} from '../actions/ActionNames';

export interface VehicleOptionState {
  data: OptionList[];
  isLoading: boolean;
  isLoaded?: boolean;
}

const initialState: VehicleOptionState = {
  data: [],
  isLoading: false,
  isLoaded: false
};

const VehicleOptionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_VEHICLE_OPTIONS_LIST:
      return initialState;
    case GET_VEHICLE_OPTIONS_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_VEHICLE_OPTIONS_LIST_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true
      };
    case GET_VEHICLE_OPTIONS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default VehicleOptionsReducer;
