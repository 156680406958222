/* eslint-disable @typescript-eslint/default-param-last */
import { Invoice } from 'src/types/Invoice';
import {
  CLEAR_INVOICES,
  DELETE_INVOICE_FAILURE,
  DELETE_INVOICE_RECEIVED,
  DELETE_INVOICE_REQUESTED,
  GET_INVOICES_FAILURE,
  GET_INVOICES_RECEIVED,
  GET_INVOICES_REQUESTED,
  UPDATE_INVOICE_FAILURE,
  UPDATE_INVOICE_RECEIVED,
  UPDATE_INVOICE_REQUESTED
} from '../actions/ActionNames';

export interface InvoiceState {
  data: any[];
  isLoading: boolean;
}

const initialState: InvoiceState = {
  data: [],
  isLoading: false
};

const InvoicesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CLEAR_INVOICES:
      return initialState;
    case GET_INVOICES_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_INVOICES_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case UPDATE_INVOICE_REQUESTED: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_INVOICE_RECEIVED: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case DELETE_INVOICE_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case DELETE_INVOICE_RECEIVED: {
      const deletedInvoiceId = action.payload.data.id;
      const newInvoices = state.data.filter(
        (invoice) => invoice.id !== deletedInvoiceId
      );
      return {
        ...state,
        data: newInvoices,
        isLoading: false
      };
    }
    case DELETE_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default InvoicesReducer;
