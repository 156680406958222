import axios from 'axios';
import { HTTP_ACTION } from '../actions/ActionNames';

import { FOUR_O_ONE } from '../../constants/ApiConstants';

import { EMPTY_STRING, OBJECT, STRING } from '../../constants/AppConstants';
import { addErrorGlobalMessageForHttpMiddleware } from '../actions/GlobalMessagesAction';

import { backendUrl } from 'src/config';

const getErrorMessageString = (error: any): string => {
  if (error) {
    if (typeof error === STRING) {
      return error;
    }
    if (typeof error === OBJECT) {
      if (error?.response?.data?.message) {
        return error.response.data.message || EMPTY_STRING;
      }
      if (error.hasOwnProperty('message')) {
        return error.message || EMPTY_STRING;
      }
      if (error.hasOwnProperty('Message')) {
        return error.Message || EMPTY_STRING;
      }
    }
    return JSON.stringify(error);
  }
  return EMPTY_STRING;
};

export const httpMiddleware = (store: any) => (next: any) => (action: any) => {
  const authToken = localStorage.getItem('auth-token');
  const companyId = localStorage.getItem('company-id');
  const headers: object = {
    Authorization: `Bearer ${authToken}`,
    'X-Company-Id': companyId,
    'Content-Type': 'application/json'
  };
  if (action && action[HTTP_ACTION]) {
    const actionInfo = action[HTTP_ACTION];
    const fetchOptions = {
      method: actionInfo.verb,
      headers: headers,
      params: actionInfo.params,
      crossDomain: true,
      data: actionInfo.payload || null
    };
    next({
      type: actionInfo.type + '_REQUESTED'
    });
    // Axios rejects the promise when a network error occurs or when API returns an error
    return axios(backendUrl + actionInfo.url, fetchOptions)
      .then((responseData) => {
        next({
          type: actionInfo.type + '_RECEIVED',
          payload: responseData.data
        });
      })
      .catch((error) => {
        if (error?.response?.status === FOUR_O_ONE) {
          localStorage.removeItem('AuthToken');
          window.location.href = '/signin';
        } else {
          addErrorGlobalMessageForHttpMiddleware(
            next,
            getErrorMessageString(error)
          );
        }
        next({
          type: actionInfo.type + '_FAILURE',
          payload: error
        });
        // return rejected promise to catch it in promise chaining. next will always return a resolved promise
        // return Promise.reject(error);
      });
  } else {
    return next(action);
  }
};
