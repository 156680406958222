import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import ThemeProvider from './theme/ThemeProvider';
import Routes from './routes';
import GlobalMessages from './bhavaniComponents/GlobalMessages';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes />
        <GlobalMessages />
        <Analytics />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
