import {
  Container,
  Avatar,
  Button,
  CssBaseline,
  Typography,
  Box,
  Paper
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormField from '../FormField';

import {
  signInFormSchema,
  signInIntialValues,
  signInValidationSchema
} from './utils';

import { signIn } from 'src/redux/actions/UserAction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
export default function SignIn() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const user: any = useSelector((state: any) => state.signin.signinUser);
  const isLoading: boolean = useSelector(
    (state: any) => state.signin.isLoading
  );

  const handleSubmit = (values: any, actions: any) => {
    dispatch(signIn(values));
    actions.setSubmitting(false);
  };

  useEffect(() => {
    if (user && user.token) {
      localStorage.setItem('auth-token', user.token);
      localStorage.setItem('user-type', user.userType);
      navigate('/', { replace: true });
    }
  }, [user, isLoading]);

  const [initialValues] = useState(signInIntialValues);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3
        }}
        component={Paper}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box width={300}>
          <Formik
            initialValues={initialValues}
            validationSchema={signInValidationSchema}
            onSubmit={(values: any, actions) => {
              handleSubmit(values, actions);
            }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                {signInFormSchema.map((option, index) => (
                  <FormField
                    key={index}
                    name={option.name}
                    type={option.type}
                    label={option.label}
                    value={values[option.name]}
                    error={errors[option.name] && touched[option.name]}
                    autoComplete={option.autoComplete || 'off'}
                  />
                ))}
                <Button
                  sx={{ mt: 3 }}
                  disabled={isSubmitting || isLoading}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Sign In
                </Button>
              </Form>
            )}
          </Formik>
          <br />
          <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
        </Box>
      </Box>
    </Container>
  );
}
