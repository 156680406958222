/* eslint-disable @typescript-eslint/default-param-last */

import { Transporter } from 'src/types/Transporter';
import {
  GET_TRANSPORTER_REQUESTED,
  GET_TRANSPORTER_RECEIVED,
  GET_TRANSPORTER_FAILURE
} from '../actions/ActionNames';

export interface TransporterState {
  data: Transporter;
  isLoading: boolean;
}

const initialState: TransporterState = {
  data: {
    companyName: '',
    ownerName: '',
    mobile: ''
  },
  isLoading: false
};

const TransporterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TRANSPORTER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_TRANSPORTER_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_TRANSPORTER_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};
export default TransporterReducer;
