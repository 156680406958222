import { httpAction } from './HttpAction';
import {
  CREATE_BANK,
  GET_BANKS,
  GET_BANK_OPTIONS_LIST,
  UPDATE_BANK,
  GET_BANK,
  DELETE_BANK
} from './ActionNames';
import { BANK_EP, DELETE, GET, POST, PUT } from 'src/constants/ApiConstants';

export const getBanks = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_BANKS,
        url: BANK_EP + '/all',
        verb: GET
      })
    );
  };
};

export const getBankOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_BANK_OPTIONS_LIST,
        url: BANK_EP + '/option-list',
        verb: GET
      })
    );
  };
};

export const createBank = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_BANK,
        url: BANK_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updateBank = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_BANK,
        url: BANK_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const getBank = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_BANK,
        url: BANK_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const deletBank = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: DELETE_BANK,
        url: BANK_EP + '/delete/' + id,
        verb: DELETE
      })
    );
  };
};
