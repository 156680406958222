import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { GlobalMessage } from 'src/types/GlobalMessage';
import { Snackbar, Alert, Typography } from '@mui/material';
import { checkForStringEqualityIgnoreCase } from 'src/utils/appUtils';
import { ALERT_TYPE_ERROR, EMPTY_STRING } from 'src/constants/AppConstants';
import { removeGlobalMessage } from 'src/redux/actions/GlobalMessagesAction';

const getAlertMessageText = (eachMessage: GlobalMessage) => {
  /* If error message is empty, show a generic error message.*/
  return eachMessage.text;
};

const GlobalMessages = () => {
  const [open, setOpen] = useState(true);
  const messages: GlobalMessage[] = useSelector(
    (state: any) => state.globalMessages.data
  );
  const dispatch: any = useDispatch();
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const closeAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
    eachMessage?: GlobalMessage
  ) => {
    dispatch(removeGlobalMessage(eachMessage?.id || EMPTY_STRING));
  };

  return (
    <>
      {messages.length > 0 &&
        messages.map((eachMessage: GlobalMessage, eachMessageIdx: number) => {
          return (
            <Snackbar
              open={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              key={eachMessageIdx}
            >
              <Alert
                color={eachMessage.type}
                onClose={(
                  event: React.SyntheticEvent | Event,
                  reason?: string
                ) => closeAlert(event, reason, eachMessage)}
                key={eachMessage.id}
              >
                {getAlertMessageText(eachMessage)}
              </Alert>
            </Snackbar>
          );
        })}
    </>
  );
};

export default GlobalMessages;
