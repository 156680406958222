import { httpAction } from './HttpAction';
import {
  CREATE_COMPANY,
  GET_COMPANIES,
  UPDATE_COMPANY,
  GET_COMPANY,
  GET_COMPANY_OPTIONS_LIST
} from './ActionNames';

import { COMPANY_EP, GET, POST, PUT } from 'src/constants/ApiConstants';

export const getCompanies = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_COMPANIES,
        url: COMPANY_EP + '/all',
        verb: GET
      })
    );
  };
};

export const createCompany = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_COMPANY,
        url: COMPANY_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updateCompany = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_COMPANY,
        url: COMPANY_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const getCompany = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_COMPANY,
        url: COMPANY_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const getCompanyOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_COMPANY_OPTIONS_LIST,
        url: COMPANY_EP + '/option-list',
        verb: GET
      })
    );
  };
};
