/* eslint-disable @typescript-eslint/default-param-last */
import { Invoice } from 'src/types/Invoice';

import {
  GET_INVOICE_REQUESTED,
  GET_INVOICE_RECEIVED,
  GET_INVOICE_FAILURE
} from '../actions/ActionNames';

export interface InvoiceState {
  data: Invoice;
  isLoading: boolean;
}

const initialState: InvoiceState = {
  data: {
    id: ''
  },
  isLoading: false
};

const InvoiceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INVOICE_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_INVOICE_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default InvoiceReducer;
