import { Company } from 'src/types/Company';
import {
  GET_COMPANY_REQUESTED,
  GET_COMPANY_FAILURE,
  GET_COMPANY_RECEIVED
} from '../actions/ActionNames';

export interface CompanyState {
  data: Company;
  isLoading: boolean;
}

/* eslint-disable @typescript-eslint/default-param-last */

const initialState: CompanyState = {
  data: {
    name: '',
    ownerName: '',
    mobile: ''
  },
  isLoading: false
};

const CompanyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_COMPANY_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_COMPANY_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default CompanyReducer;
