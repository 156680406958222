/* eslint-disable @typescript-eslint/default-param-last */
import { Transporter } from 'src/types/Transporter';

import {
  GET_TRANSPORTERS_REQUESTED,
  GET_TRANSPORTERS_RECEIVED,
  GET_TRANSPORTERS_FAILURE,
  CREATE_TRANSPORTER_REQUESTED,
  CREATE_TRANSPORTER_RECEIVED,
  CREATE_TRANSPORTER_FAILURE,
  UPDATE_TRANSPORTER_REQUESTED,
  UPDATE_TRANSPORTER_RECEIVED,
  UPDATE_TRANSPORTER_FAILURE
} from '../actions/ActionNames';

export interface TransportersState {
  data: Transporter[];
  isLoading: boolean;
}

const initialState: TransportersState = {
  data: [],
  isLoading: false
};

const TransportersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TRANSPORTERS_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_TRANSPORTERS_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_TRANSPORTERS_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    case CREATE_TRANSPORTER_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CREATE_TRANSPORTER_RECEIVED: {
      return {
        ...state,
        data: [...(state.data ?? []), action.payload.data],
        isLoading: false
      };
    }
    case CREATE_TRANSPORTER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case UPDATE_TRANSPORTER_REQUESTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case UPDATE_TRANSPORTER_RECEIVED: {
      return {
        ...state,
        data: state.data.map((transporter: Transporter) => {
          if (transporter.id === action.payload.data.id) {
            return action.payload.data;
          }
          return transporter;
        }),
        isLoading: false
      };
    }
    case UPDATE_TRANSPORTER_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default TransportersReducer;
