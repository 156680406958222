/* eslint-disable @typescript-eslint/default-param-last */

import { Customer } from 'src/types/Customer';
import {
  GET_CUSTOMER_REQUESTED,
  GET_CUSTOMER_RECEIVED,
  GET_CUSTOMER_FAILURE
} from '../actions/ActionNames';

export interface CustomerState {
  data: Customer;
  isLoading: boolean;
}

const initialState: CustomerState = {
  data: {
    companyName: '',
    ownerName: '',
    mobile: ''
  },
  isLoading: false
};

const CustomerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_CUSTOMER_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
};
export default CustomerReducer;
