/* eslint-disable @typescript-eslint/default-param-last */
import { OptionList } from 'src/types/OptionList';
import {
  GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_REQUESTED,
  GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_RECEIVED,
  GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_FAILURE,
  CLEAR_PURCHASE_TRANSPORTER_OPTIONS_LIST
} from '../actions/ActionNames';

export interface PurchaseTransporterOptionState {
  data: OptionList[];
  isLoading: boolean;
  isLoaded?: boolean;
}

const initialState: PurchaseTransporterOptionState = {
  data: [],
  isLoading: false,
  isLoaded: false
};

const PurchaseTransporterOptionsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case CLEAR_PURCHASE_TRANSPORTER_OPTIONS_LIST:
      return initialState;
    case GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_RECEIVED:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        isLoaded: true
      };
    case GET_PURCHASE_TRANSPORTER_OPTIONS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default PurchaseTransporterOptionsReducer;
