import { httpAction } from './HttpAction';

import {
  CREATE_TRANSPORTER,
  GET_TRANSPORTERS,
  GET_TRANSPORTER,
  UPDATE_TRANSPORTER,
  DELETE_TRANSPORTER,
  GET_TRANSPORTER_OPTIONS_LIST,
  GET_PURCHASE_TRANSPORTER_OPTIONS_LIST
} from './ActionNames';

import {
  TRANSPORTER_EP,
  DELETE,
  GET,
  POST,
  PUT
} from 'src/constants/ApiConstants';

export const getTransporters = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_TRANSPORTERS,
        url: TRANSPORTER_EP + '/all',
        verb: GET
      })
    );
  };
};

export const getTransporter = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_TRANSPORTER,
        url: TRANSPORTER_EP + '/get/' + id,
        verb: GET
      })
    );
  };
};

export const getTransporterOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_TRANSPORTER_OPTIONS_LIST,
        url: TRANSPORTER_EP + '/option-list',
        params: {
          type: 'loader'
        },
        verb: GET
      })
    );
  };
};

export const getPurchaseTransporterOptionsList = () => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: GET_PURCHASE_TRANSPORTER_OPTIONS_LIST,
        url: TRANSPORTER_EP + '/option-list',
        params: {
          type: 'purchase'
        },
        verb: GET
      })
    );
  };
};

export const createTransporter = (payload: any) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: CREATE_TRANSPORTER,
        url: TRANSPORTER_EP + '/create',
        verb: POST,
        payload: payload
      })
    );
  };
};

export const updateTransporter = (payload: any, id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: UPDATE_TRANSPORTER,
        url: TRANSPORTER_EP + '/update/' + id,
        verb: PUT,
        payload: payload
      })
    );
  };
};

export const deleteTransporter = (id: string) => {
  return (dispatch: any) => {
    dispatch(
      httpAction({
        type: DELETE_TRANSPORTER,
        url: TRANSPORTER_EP + '/delete/' + id,
        verb: DELETE
      })
    );
  };
};
