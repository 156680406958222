import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import AuthGuard from 'src/utils/route-guard/AuthGuard';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const BhavaniDashboard = Loader(
  lazy(() => import('src/bhavaniComponents/BhavaniDashboard'))
);

const Settings = Loader(lazy(() => import('src/bhavaniComponents/Settings')));

const BankList = Loader(lazy(() => import('src/bhavaniComponents/BankList')));
const CompanyList = Loader(
  lazy(() => import('src/bhavaniComponents/CompanyList'))
);
const CustomerList = Loader(
  lazy(() => import('src/bhavaniComponents/CustomerList'))
);

const TransporterList = Loader(
  lazy(() => import('src/bhavaniComponents/TransporterList'))
);

const PlantList = Loader(lazy(() => import('src/bhavaniComponents/PlantList')));

const ProductList = Loader(
  lazy(() => import('src/bhavaniComponents/ProductList'))
);

const Purchase = Loader(lazy(() => import('src/bhavaniComponents/Purchase')));

const InvoiceList = Loader(
  lazy(() => import('src/bhavaniComponents/InvoiceList'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

const MainRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/dashboard', element: <BhavaniDashboard /> },
      { path: '/dashboards/crypto', element: <Crypto /> },
      { path: '/dashboards/messenger', element: <Messenger /> },
      { path: '/system/banks', element: <BankList /> },
      { path: '/system/companies', element: <CompanyList /> },
      { path: '/system/customers', element: <CustomerList /> },
      { path: '/system/transporters', element: <TransporterList /> },
      { path: '/system/plants', element: <PlantList /> },
      { path: '/system/products', element: <ProductList /> },
      { path: '/system/settings', element: <Settings /> },
      { path: '/main/purchase', element: <Purchase /> },
      { path: '/main/invoice', element: <InvoiceList /> },
      { path: '/management/transactions', element: <Transactions /> },
      { path: '/management/profile/details', element: <UserProfile /> },
      { path: '/management/profile/settings', element: <UserSettings /> },
      { path: '/components/buttons', element: <Buttons /> },
      { path: '/components/modals', element: <Modals /> },
      { path: '/components/accordions', element: <Accordions /> },
      { path: '/components/tabs', element: <Tabs /> },
      { path: '/components/badges', element: <Badges /> },
      { path: '/components/tooltips', element: <Tooltips /> },
      { path: '/components/avatars', element: <Avatars /> },
      { path: '/components/cards', element: <Cards /> },
      { path: '/components/forms', element: <Forms /> }
    ]
  }
];

export default MainRoutes;
